import Constants from "./constants";

const setPropData = (companyDetails, callback) => {
  const additionalDetails = JSON.stringify(
    companyDetails.properties.amp_additional_detail
  );

  let tempCards = Constants.CardsArrTemplate.map((item, index) => {
    if (item.feature === "Amp Analytics") {
      const indexOfAnalytics = additionalDetails.indexOf(
        "AMP Analytics Status:"
      );
      const ampAnalyticsStatus = additionalDetails.charAt(
        indexOfAnalytics + 21
      );

      item.usingFeature = ampAnalyticsStatus === "t" ? true : false;
    } else if (item.feature === "RTC")
      item.usingFeature = companyDetails.properties.amp_using_rtc_config;
    else if (item.feature === "Multi Size Ads")
      item.usingFeature = companyDetails.properties.amp_multisize_status;
    else if (item.feature === "Ad Refresh")
      item.usingFeature = companyDetails.properties.amp_ads_refresh_status;
    else if (item.feature === "Sticky Ads") {
      const indexOfSticky = additionalDetails.indexOf("Sticky Score:");
      const stickyAdsStatus = additionalDetails.charAt(indexOfSticky + 13);
      item.usingFeature = stickyAdsStatus === 3 ? true : false;
    }

    return item;
  });

  callback(tempCards);
};

const setPropDataFromTags = (tagData, callback) => {
  let tempCards = Constants.CardsArrTemplate.map((item, index) => {
    if (item.feature === "RTC") item.usingFeature = tagData.rtc;
    else if (item.feature === "Multisize Ads")
      item.usingFeature = tagData.multi;
    else if (item.feature === "Ad Refresh") item.usingFeature = tagData.refresh;
    else if (item.feature === "Sticky Ads") item.usingFeature = tagData.sticky;
    else if (item.feature === "Amp Analytics")
      item.usingFeature = tagData.analytics;
    else if (item.feature === "Amp Consent")
      item.usingFeature = tagData.consent;
    else if (item.feature === "Viewable Refresh")
      item.usingFeature =
        tagData.dcp.includes("doubleclick") && tagData.refresh;

    console.log(item);
    return item;
  });

  console.log(tempCards);

  callback(tempCards);
};

export { setPropData, setPropDataFromTags };
