import "../../styles/HeroSection.css";
import { Link } from "react-scroll";
import HeroImage from "../../assets/Intro.svg";

function HeroSection({ compName, tagData }) {

  console.log(compName)
  if(compName == "SPOKESPERSON NEWSPAPER")compName="Sözcü";

  let baseScore = 60;

  if (tagData.sticky === true) {
    baseScore += 3.8;
  }
  if (tagData.refresh === true) {
    baseScore += 4.4;
  }
  if (tagData.rtc === true) {
    baseScore += 4.1;
  }
  if (tagData.multi === true) {
    baseScore += 3.4;
  }
  if (tagData.consent === true) {
    baseScore += 2.4;
  }
  if (tagData.dcp.includes("doubleclick")) {
    baseScore += 2.7;
  }
  return (
    <div className="h-screen bg-white overflow-hidden flex flex-row justify-between">
      <div className="color-rect sunsetOrange"></div>
      <div className="color-rect cornflowerBlue"></div>
      <div className="color-rect sunglow"></div>
      <div className="color-rect tuna"></div>

      <div></div>

      <div className="max-w-xl pb-10 ml-5 lg:ml-20 ">
        <div className="pb-16 md:pb-20 lg:pb-28 xl:pb-32">
          <main
            className="mx-auto px-4 sm:px-6 lg:px-8"
            style={{ marginTop: "6rem" }}
          >
            <div className="sm:text-center lg:text-left pt-10">
              <h1 className="text-tuna text-6xl md:text-7xl lg:text-9xl font-extrabold">
                <p className=" uppercase text-4xl mt-5 ml-2">{compName}</p>
                <p className="block xl:inline uppercase">
                  AMP Yield Index 2022
                </p>
              </h1>
            </div>
          </main>
        </div>
      </div>
      <main className="invisible lg:visible w-10/12 lg:w-9/12 py-16 mx-auto flex gap-x-10 items-center justify-center bg-sunsetOrange">
        <div className="text-justify">
          <h1 className="tracking-tight font-extrabold text-white mb-10 text-6xl  lg:text-7xl">
            <span className="block xl:inline">Introduction</span>
          </h1>
          <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Offering a good user experience while optimizing your ad revenues is
            a difficult feat to achieve. In that spirit, as a part of our AMP
            Yield Index program, we conducted a thorough and objective analysis
            of your AMP setup and found that there are certain key optimization
            areas.
          </p>
          <br />
          <div className="text-center">
            <p className="text-white text-2xl font-bold">
              AMP 360° Ad Audit Score: {Math.round(baseScore * 10) / 10}
              {/* {companyDetails?.properties.amp_score} */}
            </p>
          </div>
          <br />
          <div className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
           For this report, our audit team checked for the following parameters:
            <br />
            <br />
            <ul className="text-white list-disc mx-10 text-left">
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Your AMP AdX setup
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Whether or not you're using header bidding
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  The state of Ad Refresh
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Multisize ad request support
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Sticky ads
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Whether you're using AMP analytics.
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  AMP Consent
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Viewable Refresh
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}

export default HeroSection;
