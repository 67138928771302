import { useState, useEffect } from "react";

function CurrSetup({ compName, tagData, compId }) {
  const [screenshotUrl, setScreenshotUrl] = useState("");

  useEffect(() => {
    if (compId)
      setScreenshotUrl(
        `https://5928778.fs1.hubspotusercontent-na1.net/hubfs/5928778/AMP%20Yield/${compId}.jpeg`
      );
  }, [compId]);
  return (
    <div className="bg-tuna h-full overflow-hidden">
      <main className="w-11/12  lg:w-9/12 py-16 mx-auto flex flex-col items-center justify-center ">
        <h1 className=" font-extrabold text-white mb-20 text-4xl md:text-5xl">
          <span className="block xl:inline">
            {compName}'s Current Amp Setup :
          </span>
        </h1>

        {/* Desktop */}

        <div className="hidden md:flex md:flex-row md:items-center md:justify-around lg:justify-between md:max-w-screen-lg md:mx-auto">
          <div className="md:w-4/12 lg:w-8/12 text-center">
            <p className="bg-white rounded-md p-5 text-left break-words font-mono">
              {tagData.tag}
            </p>
            <br />

            <p className=" font-bold text-white">Amp tags from {compName}</p>
          </div>
          <div className="md:w-20 lg:w-60 flex flex-col items-center">
            <img className="w-full rounded-lg" src={screenshotUrl} alt="" />
            <br />
            <p className=" font-bold text-white">Screenshot from {compName}</p>
          </div>
        </div>

        {/* Mobile */}

        <div className=" w-full flex flex-col-reverse items-center justify-center mx-auto md:hidden">
          {/* ' <div className="w-80 text-center">
            <p className="bg-white rounded-md p-5 text-left break-words font-mono">
              {tagData.tag}
            </p>
            <br />
            <p className=" font-bold text-white">Amp tags from {compName}</p>
          </div>' */}
          <div className="flex flex-col mb-10 items-center">
            <img className="w-60 rounded-lg" src={screenshotUrl} alt="" />
            <br />
            <p className=" font-bold text-white">Screenshot from {compName}</p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default CurrSetup;
