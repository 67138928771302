import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

//Section Imports
import HeroSection from "./HeroSection";
import Introduction from "./Intro";
import FeatureSection from "./FeatureSection";
import Conclusion from "./Conclusion";
import ReachOut from "./ReachOut";
import CurrSetup from "./CurrSetup";
import { Loader } from "../../components";
import Constants from "../../core/constants";
import ScrollContactButton from "../../components/ScrollContactButton";

function GenieeAuditScreen(props) {
  const [ampTag, setAmpTag] = useState();

  const [companyDetails, setCompanyDetails] = useState();
  const { sitename } = useParams();

  useEffect(() => {
    fetchAmpTagData(sitename);
  }, []);

  // const fetchData = (companyHubspotID) => {
  //   axios
  //     .post(
  //       Constants.urls.compDetailUrl,
  //       new URLSearchParams({
  //         companyHubspotID: companyHubspotID,
  //       }),
  //       Constants.axiosConfig
  //     )
  //     .then((res) => {
  //       // console.log(res.data);
  //       if (res.data) setCompanyDetails(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const fetchAmpTagData = (sitename) => {
    axios
      .post(
        Constants.urls.genieeAmpTagUrl,
        new URLSearchParams({
          website: sitename,
        }),
        Constants.axiosConfig
      )
      .then((res) => {
        if (res.data && Object.keys(res.data).length > 0) {
          console.log(res.data);
          setAmpTag(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {companyDetails || ampTag ? (
        <>
          <HeroSection
            compName={ampTag.name}
            tagData={ampTag}
          />

          {/* <Introduction
            companyDetails={companyDetails}
            compId={props?.match?.params?.sitename}
            tagData={ampTag}
          /> */}
          <CurrSetup
            compName={ampTag.name}
            tagData={ampTag}
            compId={props?.match?.params?.sitename}
          />
          {/* <FeatureSection companyDetails={companyDetails} tagData={ampTag} /> */}
          <Conclusion tagData={ampTag} />
          <ReachOut compId={props?.match?.params?.sitename} />
          <ScrollContactButton compId={props?.match?.params?.sitename} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default GenieeAuditScreen;
