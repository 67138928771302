import "./styles/App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";

import { Navbar, Footer, ScrollButton } from "./components";
import AuditScreen from "./screens/AuditScreen";
import CompetitionAnalysis from "./screens/CompetitionAnalysis/index";
import GenieeAuditScreen from "./screens/GenieeAuditScreen";
import GenieeFooter from "./components/GenieeFooter";
import NotFound from "./screens/404";
// import { useGA4React } from "ga-4-react";
// import { useEffect } from "react";
import ReactGA from "react-ga";

function App() {
  // const ga = useGA4React();

  // useEffect(()=>{
  //   if(ga)
  //   ga.pageview(window.location.pathname + window.location.search)
  // },[ga])

  ReactGA.initialize("UA-40677919-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [isGeniee, setIsGeniee] = useState(false);

  return ( 
    <Router>
      {/* <Navbar /> */}
      <Switch>
        <Route path="/compAnalysis/:companyID" component={CompetitionAnalysis} />
        <Route path="/:companyID" component={AuditScreen} exact/>
        <Route
          path="/geniee/:sitename"
          render={() => {
            setIsGeniee(true);
            return <GenieeAuditScreen />;
          }}
        />
        {/* <Route path="/*" component={NotFound} /> */}
      </Switch>
      <ScrollButton />
      {isGeniee ? <GenieeFooter /> : <Footer />}
    </Router>
  );
}

export default App;
