import HeroImage from "../../assets/Intro.svg";

import { Link } from "react-scroll";
function Introduction({ companyDetails, compId, tagData }) {
  let baseScore = 60;

  if (tagData.sticky === true) {
    baseScore += 3.8;
  }
  if (tagData.refresh === true) {
    baseScore += 4.4;
  }
  if (tagData.rtc === true) {
    baseScore += 4.1;
  }
  if (tagData.multi === true) {
    baseScore += 3.4;
  }
  if (tagData.consent === true) {
    baseScore += 2.4;
  }
  if (tagData.dcp.includes("doubleclick")) {
    baseScore += 2.7;
  }
  return (
    <div className="bg-sunsetOrange h-full overflow-hidden  block md:hidden">
      <main className=" w-10/12 lg:w-9/12 py-16 mx-auto flex gap-x-10 items-center justify-center">
        <div className="text-justify">
          <h1 className="tracking-tight font-extrabold text-white mb-10 text-6xl  lg:text-7xl">
            <span className="block xl:inline">Introduction</span>
          </h1>
          <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Offering a good user experience while optimizing your ad revenues is
            a difficult feat to achieve. In that spirit, as a part of our AMP
            Yield Index program, we conducted a thorough and objective analysis
            of your AMP setup and found that there are certain key optimization
            areas.
          </p>
          <br />
          <div className="text-center">
            <p className="text-white text-2xl font-bold">
              AMP 360° Ad Audit Score: {Math.round(baseScore * 10) / 10}
              {/* {companyDetails?.properties.amp_score} */}
            </p>
          </div>
          <br />
          <div className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            For this report, our audit team took a look at the following:
            <br />
            <br />
            <ul className=" text-white list-disc mx-10 text-left">
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Your AMP AdX setup
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Whether or not you're using header bidding
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  The state of Ad Refresh
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Multisize ad request support
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Sticky ads
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Whether you're using AMP analytics.
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  AMP Consent
                </Link>
              </li>
              <li className="py-1">
                <Link to="intro" spy={true} smooth={true}>
                  Viewable Refresh
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="lg:w-1/2 hidden lg:block mt-20">
          <img src={HeroImage} alt="Audit" />
        </div> */}
      </main>
    </div>
  );
}

export default Introduction;
