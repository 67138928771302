import React from "react";
import AdpushupSymbol from "../assets/symbolWhite.png";
import AdpushupLogo from "../assets/textLogoWhite.png";
import GenieeLogo from "../assets/GenieeLogo.png";
import GoogleCertifiedImage from "../assets/GooglecertifiedImage.png";
import criteoBadge from "../assets/criteo-partner-badge-v2.png";
import { Socials } from "./index";

const GenieeFooter = () => {
  const handleCTAClick = () => {
    // ga.event('CTA Clicked','AMP Audit',92378454)

    const parameters = new URLSearchParams(window.location.search);
    const value = parameters.get("contact");
    // ReactGA.event({
    //   category: "Amp Audit Report Footer",
    //   action: `${value}`,
    // });
  };
  return (
    <div className="w-full ">
      <div className="w-9/12 mx-auto py-12 grid  grid-cols-2  lg:grid-cols-4 gap-10">
        <div className=" col-span-2   flex flex-col space-y-4 items-start justify-start gap-x-3">
          <img src={GenieeLogo} width={140} alt={"Logo"} height={7} />
          <div className="flex flex-row gap-x-5">
            <img
              src={GoogleCertifiedImage}
              width={140}
              alt={"Logo"}
              height={7}
            />
            <img src={criteoBadge} width={160} alt={"Logo"} />
          </div>

          <p className="flex flex-col">
            <span className="has-inline-color">
              <sub>
                © 2022 Geniee Group. All rights reserved. By Baamboo Studio
              </sub>
            </span>
          </p>
        </div>
        <div className="w-full flex flex-col space-y-2">
          <p className="my-1 font-bold text-lg antialiased ">
            <svg
              class="h-8 w-8 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </p>
          <p className="my-1 font-bold text-lg antialiased ">Geniee Vietnam</p>
          <a href="">
            4th floor, Ngoc Khanh Plaza Building, No.1 Pham Huy Thong, Ngoc
            Khanh Ward, Ba Dinh District, Hanoi
          </a>
          <a href="">
            <strong>Tel: </strong> +84 24 3795 4257
          </a>
          <a href="mailto: gi_info@geniee.co.jp">gi_info@geniee.co.jp</a>
        </div>
        <div className="w-full  flex flex-col space-y-2">
          <p className="my-1 font-bold text-lg antialiased ">
            <svg
              class="h-8 w-8 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </p>
          <h6 className="my-1 font-bold text-lg antialiased ">
            Geniee Indonesia
          </h6>
          <a href="">
            World Trade Center II Building, 9th Floor Jl. Jend. Sudirman Kav.
            29, Setiabudi, Jakarta Selatan DKI Jakarta 12920, Indonesia
          </a>
          <a href="">
            <strong>Tel: </strong> +62 2129522628
          </a>
          <a href="mailto: publisher-gid@geniee.co.jp">
            publisher-gid@geniee.co.jp
          </a>
          <a href="mailto: sales@adstarsmedia.co.id">
            sales@adstarsmedia.co.id
          </a>
        </div>
        {/* <div className="w-full  flex flex-col space-y-2">
          <a
            onClick={handleCTAClick}
            className="w-full shadow text-center font-medium rounded-md text-tuna bg-white py-4 md:text-lg antialiased transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 "
            href="https://campaign.adpushup.com/get-started/?utm_campaign=Outbound&utm_source=AMPAuditWebsite&utm_medium=Footer"
          >
            Speak to us
          </a>
          <br />
          <Socials />
        </div> */}
      </div>
    </div>
  );
};

export default GenieeFooter;
