const backendUrl =
  process?.env?.NODE_ENV === "production" ? "" : "http://localhost:6789";

const Constants = {
  CardsArrTemplate: [
    {
      feature: "Ad Refresh",
      usingFeature: false,
    },
    { feature: "DemandAnalysis", usingFeature: false },
    { feature: "RTC", usingFeature: false },
    {
      feature: "Multisize Ads",
      usingFeature: false,
    },
    {
      feature: "Sticky Ads",
      usingFeature: false,
    },
    { feature: "Amp Analytics", usingFeature: true },
    { feature: "Amp Consent", usingFeature: false },
    { feature: "Viewable Refresh", usingFeature: false },
  ],
  urls: {
    compDetailUrl: backendUrl + "/api/getCompnayDetails",
    ampTagUrl: backendUrl + "/api/getAmpTag",
    genieeAmpTagUrl: backendUrl + "/api/getGenieeAmpTag",
    getCompetitors:"/api/getCompetitors"
  },
  links: {
    requestDemoLink:
      "https://campaign.adpushup.com/get-started/?utm_campaign=Outbound&utm_source=AMPAuditWebsite&utm_medium=CTA",
  },
  axiosConfig: {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
};

export default Constants;
