import { Finding } from "./index";

import AnalyticsSvg from "../assets/analytics.svg";
import RTCSvg from "../assets/rtc.svg";
import AdRefreshSvg from "../assets/adRefresh.svg";
import MultiSizeSvg from "../assets/multisize.svg";
import StickySvg from "../assets/stickyAds.svg";
import DemandSvg from "../assets/report.svg";
import AcceptTermsSvg from "../assets/acceptTerms.svg";
import ViewableRefreshSvg from "../assets/viewableRefresh.svg";

function Findings({ propertiesArr, compName }) {
  const demandData = {
    title: "AdX Setup",
    findingsArr: [
      "💡 We found that you're using the default demand offered by Google Ad Manager for your AMP inventory",
      "Open Bidding (formerly EBDA) setup details are not available",
    ],
    recommendArr: [
      "Incase open bidding is not configured, Adpushup recommends enabling the same",
      `✅ We also recommend exploring the use of server-side header bidding, client-side header bidding, and real-time config to access demand from a greater number of demand partners, increasing your match rates, fill rates, and eCPMs.`,
    ],
    image: DemandSvg,
  };

  const rtcData = {
    title: "Client-side Header Bidding",
    findingsArr: [
      "No client-side header bidding for AMP inventory",
      "💡 Sole reliance on default ad networks such as Google Ad Exchange, supported by Google Ad ",
      "Server side bidding via rtc-config is not configured",
    ],
    recommendArr: [
      "✅ Implement client-side header bidding for AMP, increasing your eCPMs and fill rates, as you'll be opening up your inventory to <b>30+ additional demand partners</b>",
      "Client side header bidding gives you greater transparency and control over the entire header bidding process, as compared to the standard <amp-ad> setup.",
      "While you could consider server side bidding setups via real time config (the default AMP server side bidding setup), as a part of a design limitation, you would not be able to connect with more than five demand partners. However, with a client side header bidding setup, there's no upper limit to the number of bidders you can enable",
    ],
    image: RTCSvg,
  };

  const rtcEnabledData = {
    title: "Client-side Header Bidding",
    findingsArr: [
      "No client-side header bidding for AMP inventory",
      "💡 Sole reliance on default ad networks such as Google Ad Exchange, supported by Google Ad Manager and server-side bidding via RTC-config",
    ],
    recommendArr: [
      "✅ Implement client-side header bidding for AMP, increasing your eCPMs and fill rates, as you'll be opening up your inventory to <b>30+ additional demand partners</b>",
      "Client side header bidding gives you greater transparency and control over the entire header bidding process, as compared to the standard <amp-ad> setup.",
      "With server side bidding setups via real time config (the default AMP server side bidding setup), as a part of a design limitation, you would not be able to connect with more than five demand partners. However, with a client side header bidding setup, there's no upper limit to the number of bidders you can enable",
    ],
    image: RTCSvg,
  };

  const adRefreshData = {
    title: "Ad Refresh",
    findingsArr: [
      "There is no Ad Refresh on the AMP inventory. Once an ad is served, the same ad is displayed for the entire session on a particular page",
      "💡 Considering your average time on page is much higher than the required minimum threshold, you could drastically increase the number of impressions served by using ad refresh",
    ],
    recommendArr: [
      `✅ AdPushup recommends that ${compName} enable Ad Refresh on their AMP inventory`,
      "Considering a high time on page, refreshing the ads at regular intervals will result in a <b>revenue uplift in the range of 35-40%</b>",
      "The refresh interval can be decided on the basis of the average session duration and average time on page of the users on the website",
    ],
    image: AdRefreshSvg,
    popoverContent: "Did you know that Adsense does not support Ad refresh?",
  };

  const multisizeData = {
    title: "Multisize Ad Request Support",
    findingsArr: [
      "No support for multiple ad sizes on AMP inventory",
      "💡 Multisize ad request support helps more advertisers compete for the same ad slot, increasing the <b>revenue earning potential</b> of that ad slot",
    ],
    recommendArr: [
      "✅ Adpushup recommends enabling support for multiple ad sizes in the same ad slot, which helps increase your ad yield",
      "More number of advertisers bid for the same ad inventory, which gives you <b>better match rates and higher eCPMS</b>",
      "It should be noted that enabling multi-size ads has no impact on the stability of your layout",
      "The stable layout prevents the content from 'jumping around', eliminating the problem of a poor user experience, and helps maintain a good CLS score",
    ],
    image: MultiSizeSvg,
  };

  const multisizeEnabledData = {
    title: "Multisize Ad Request Support",
    findingsArr: [
      "Support exists for multiple ad sizes on AMP inventory",
      "💡 Multisize ad request support helps more advertisers compete for the same ad slot, increasing the <b>revenue earning potential</b> of that ad slot",
    ],
    recommendArr: [
      "✅ Adpushup applauds enabling the support for multiple ad sizes in the same ad slot, which helps increase your ad yield",
      "More number of advertisers bid for the same ad inventory, which gives you <b>better match rates and higher eCPMS</b>",
      "It should be noted that enabling multi-size ads has no impact on the stability of your layout",
      "The stable layout prevents the content from 'jumping around', eliminating the problem of a poor user experience, and helps maintain a good CLS score",
    ],
    image: MultiSizeSvg,
  };

  const stickyAdsData = {
    title: "Sticky Ads",
    findingsArr: [
      "Sticky ads have not been implemented on AMP pages",
      "💡 Sticky ads have nearly <b>100% viewability</b>, a feat that's unattainable for most other formats",
      "Higher viewability attracts greaters spends from advertisers, eventually increasing your eCPMs and RPMs",
    ],
    recommendArr: [
      "✅ Adpushup recommends implementing sticky ads on your AMP inventory",
      "Sticky ads are by far the highest paying format in most setups. If implemented well (with multi-size support and header bidding), you can expect a <b>revenue uplift of at least 10%</b>",
      "In case there are any user experience concerns, it should be noted that sticky ads, if implemented well, have no impact on the visitor's experience. In order to iron out these concerns, if any, we recommend monitoring the pages per session and revenue per user metrics to understand if there's any negative impact",
    ],
    image: StickySvg,
    popoverContent:
      "Sticky ads improve the ad viewability score and fetch higher eCPMs",
  };

  const stickyAdsEnabledData = {
    title: "Sticky Ads",
    findingsArr: [
      "Sticky ads have been implemented on AMP pages",
      "💡 Sticky ads have nearly <b>100% viewability</b>, a feat that's unattainable for most other formats",
      "Higher viewability attracts greaters spends from advertisers, eventually increasing your eCPMs and RPMs",
    ],
    recommendArr: [
      "✅ Adpushup applauds your implementation of sticky ads on your AMP inventory",
      "Sticky ads are by far the highest paying format in most setups. If implemented well (with multi-size support and header bidding), you can expect a <b>revenue uplift of at least 10%</b>",
      "In case there are any user experience concerns, it should be noted that sticky ads, if implemented well, have no impact on the visitor's experience. In order to iron out these concerns, if any, we recommend monitoring the pages per session and revenue per user metrics to understand if there's any negative impact",
    ],
    image: StickySvg,
    popoverContent:
      "Sticky ads improve the ad viewability score and fetch higher eCPMs",
  };

  const analyticsData = {
    title: "Amp Analytics",
    findingsArr: [
      "Amp-analytics component is not enabled",
      "No amp-analytics results in a lack of optimization efforts, as user interactions on AMP pages are not being tracke",
    ],
    recommendArr: [
      "✅ Adpushup recommends enabling amp-analytics to capture analytics data from accelerated mobile pages",
      "Analytics data gives you insights into user behavior and helps you figure out why they're bouncing off your site",
    ],
    image: AnalyticsSvg,
  };

  const analyticsEnabledData = {
    title: "Amp Analytics",
    findingsArr: [
      "Amp-analytics component is enabled",
      "Amp-analytics helps you optimize your setup, as user interactions can be tracked and decisions can be taken accordingly",
    ],
    recommendArr: [
      "✅ Adpushup applauds the use of amp-analytics to capture analytics data from accelerated mobile pages",
      "Analytics data gives you insights into user behavior and helps you figure out why they're bouncing off your site",
    ],
    image: AnalyticsSvg,
  };

  const consentData = {
    title: "Amp Consent",
    findingsArr: [
      "AMP Consent has not been implemented on AMP pages",
      "💡Your AMP website may not be compliant with privacy legislations like European Union's GDPR and CCPA (California Consumer Privacy Act)",
    ],
    recommendArr: [
      "✅ AdPushup strongly recommends implementing AMP Consent on your AMP pages, to avoid any penalties",
      "You should ensure that the consent management platform implemented is compliant with TCF v2.0",
    ],
    image: AcceptTermsSvg,
  };

  const consentEnabledData = {
    title: "Amp Consent",
    findingsArr: [
      "AMP Consent has been implemented on AMP pages",
      "💡The consent module helps you in being compliant with privacy legislations like European Union's GDPR and CCPA (California Consumer Privacy Act)",
    ],
    recommendArr: [
      "💡It is recommended that you should ensure your consent management platform is TCF v2.0 compliant",
      "AMP Consent enhances transparency and, in turn, builds trust between the users and the website",
      "They also ensure that you're in compliance with most of the cookie regulating laws around the globe, which in turn, saves you from severe penalties in future",
    ],
    image: AcceptTermsSvg,
  };

  const viewableRefreshData = {
    title: "Viewable Refresh",
    findingsArr: [
      "Your AMP ad tags are not refreshing the ads when the unit is in-view (active view)",
      "In the absence of active-view refresh, the ad revenue yield would be significantly lower",
      "💡 Active view refresh is excellent for boosting viewability and increasing the overall ad revenue yield",
    ],
    recommendArr: [
      "✅ The current AMP revenue can be increased by upto 30% by implementing active view refresh",
      "Refreshing your AMP ad tags without active view refresh will lower the viewability of your inventory and will lead to a large drop in revenue yield",
      "Consider using header bidding demand with active view refresh",
    ],
    image: ViewableRefreshSvg,
  };

  const viewableRefreshEnabledData = {
    title: "Viewable Refresh",
    findingsArr: [
      "Your AMP ad tags are refreshing the ads when the unit is in-view (active view)",
      "💡 Active view refresh is excellent for boosting viewability and increasing the overall ad revenue yield",
    ],
    recommendArr: [
      "✅ AdPushup applauds the implementation of viewable refresh",
      "Ensure that the IAB standard minimum criteria for impression viewability (time and percentage of pixels viewable) is met",
      "Combine active view refresh with header bidding demand to boost your yield even further",
    ],
    image: ViewableRefreshSvg,
  };

  return (
    <section className="py-4">
      <div className="grid gap-y-8 my-8 grid-cols-1 ">
        {propertiesArr.map((item, index) => {
          /* Ad Refrsh UI Card */

          if (
            item.feature === "Ad Refresh" &&
            (!item.usingFeature || item.usingFeature === "false")
          )
            return (
              <Finding
                key={"finding" + adRefreshData.title + index}
                title={adRefreshData.title}
                findings={adRefreshData.findingsArr}
                recommendations={adRefreshData.recommendArr}
                image={adRefreshData.image}
                onLeft={false}
                popoverData={adRefreshData.popoverContent}
              />
            );

          /* Demand Analysis Card */

          if (item.feature === "DemandAnalysis")
            return (
              <Finding
                key={"finding" + demandData.title + index}
                title={demandData.title}
                findings={demandData.findingsArr}
                recommendations={demandData.recommendArr}
                image={demandData.image}
                onLeft={true}
              />
            );

          /* RTC Config UI Card */

          if (item.feature === "RTC")
            return !item.usingFeature ? (
              <Finding
                key={"finding" + rtcData.title + index}
                title={rtcData.title}
                findings={rtcData.findingsArr}
                recommendations={rtcData.recommendArr}
                image={rtcData.image}
                onLeft={false}
              />
            ) : (
              <Finding
                key={"finding" + rtcEnabledData.title + index}
                title={rtcEnabledData.title}
                findings={rtcEnabledData.findingsArr}
                recommendations={rtcEnabledData.recommendArr}
                image={rtcEnabledData.image}
                onLeft={false}
              />
            );

          /* Multisize Ads UI Card */

          if (item.feature === "Multisize Ads")
            return !item.usingFeature ? (
              <Finding
                key={"finding" + multisizeData.title + index}
                title={multisizeData.title}
                findings={multisizeData.findingsArr}
                recommendations={multisizeData.recommendArr}
                image={multisizeData.image}
                onLeft={true}
              />
            ) : (
              <Finding
                key={"finding" + multisizeEnabledData.title + index}
                title={multisizeEnabledData.title}
                findings={multisizeEnabledData.findingsArr}
                recommendations={multisizeEnabledData.recommendArr}
                image={multisizeEnabledData.image}
                onLeft={true}
              />
            );

          /* Sticky Ads UI Card */
          if (item.feature === "Sticky Ads")
            return !item.usingFeature ? (
              <Finding
                key={"finding" + stickyAdsData.title + index}
                title={stickyAdsData.title}
                findings={stickyAdsData.findingsArr}
                recommendations={stickyAdsData.recommendArr}
                image={stickyAdsData.image}
                onLeft={false}
                popoverData={stickyAdsData.popoverContent}
              />
            ) : (
              <Finding
                key={"finding" + stickyAdsEnabledData.title + index}
                title={stickyAdsEnabledData.title}
                findings={stickyAdsEnabledData.findingsArr}
                recommendations={stickyAdsEnabledData.recommendArr}
                image={stickyAdsEnabledData.image}
                onLeft={false}
                popoverData={stickyAdsEnabledData.popoverContent}
              />
            );

          /* Analytics UI Card */

          if (item.feature === "Amp Analytics")
            return !item.usingFeature ? (
              <Finding
                key={"finding" + analyticsData.title + index}
                title={analyticsData.title}
                findings={analyticsData.findingsArr}
                recommendations={analyticsData.recommendArr}
                image={analyticsData.image}
                onLeft={true}
              />
            ) : (
              <Finding
                key={"finding" + analyticsEnabledData.title + index}
                title={analyticsEnabledData.title}
                findings={analyticsEnabledData.findingsArr}
                recommendations={analyticsEnabledData.recommendArr}
                image={analyticsEnabledData.image}
                onLeft={true}
              />
            );

            /* Consent UI Card */

            if (item.feature === "Amp Consent")
              return !item.usingFeature ? (
                <Finding
                  key={"finding" + consentData.title + index}
                  title={consentData.title}
                  findings={consentData.findingsArr}
                  recommendations={consentData.recommendArr}
                  image={consentData.image}
                  onLeft={false}
                />
              ) : (
                <Finding
                  key={"finding" + consentEnabledData.title + index}
                  title={consentEnabledData.title}
                  findings={consentEnabledData.findingsArr}
                  recommendations={consentEnabledData.recommendArr}
                  image={consentEnabledData.image}
                  onLeft={false}
                />
              );

              if (item.feature === "Viewable Refresh")
              return !item.usingFeature ? (
                <Finding
                  key={"finding" + viewableRefreshData.title + index}
                  title={viewableRefreshData.title}
                  findings={viewableRefreshData.findingsArr}
                  recommendations={viewableRefreshData.recommendArr}
                  image={viewableRefreshData.image}
                  onLeft={true}
                />
              ) : (
                <Finding
                  key={"finding" + viewableRefreshEnabledData.title + index}
                  title={viewableRefreshEnabledData.title}
                  findings={viewableRefreshEnabledData.findingsArr}
                  recommendations={viewableRefreshEnabledData.recommendArr}
                  image={viewableRefreshEnabledData.image}
                  onLeft={true}
                />
              );

          return null;
        })}
      </div>
    </section>
  );
}

export default Findings;
