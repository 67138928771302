import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
function FeatureTable({ tagData }) {
  console.log(tagData);
  return (
    <div className="mx-auto rounded-md my-10 sm:w-full lg:w-8/12 bg-white text-tuna">
      <table className="table-auto border-collapse px-4 ">
        <thead className="text-xl lg:text-2xl">
          <tr>
            <th className="py-3">Criteria</th>
            <th className="py-3">Assessment</th>
          </tr>
        </thead>

        <tbody className="text-lg">
          <tr>
            <td className="w-1/2 py-1">AdX Setup</td>
            <td className="w-1/3 py-1">
              {/* {tagData.dcp.includes('doubleclick') ? (
              <FontAwesomeIcon icon={faCheck} />
              ) : (
              <FontAwesomeIcon icon={faTimes} />
              )} */}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1">Header Bidding</td>
            <td className="w-1/3 py-1">
              {tagData.rtc ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1">Ad Refresh</td>
            <td>
              {tagData.refresh ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1">Multisize Ad Request Support</td>
            <td>
              {tagData.multi ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1">Sticky Ads</td>
            <td>
              {tagData.sticky ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1">AMP Analytics</td>
            <td>
              {tagData.analytics ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1">AMP Consent</td>
            <td>
              {tagData.consent ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
          </tr>
          <tr>
            <td className="w-1/2 py-1 pb-3">Viewable Refresh</td>
            <td className="pb-3">
              {/* {tagData.dcp.includes('doubleclick') && tagData.refresh ? (
              <FontAwesomeIcon icon={faCheck} />
              ) : (
              <FontAwesomeIcon icon={faTimes} />
              )} */}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FeatureTable;
